@use '../base/vars' as *;

.silider{
    
    width: 100%;
    // max-width: 1000px;
    max-width: 972px;
    width: 100%;
    margin: 50px 0 0 0;
    
    img{
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
    
    a{
        transition: opacity .4s;
        
        &:hover{
            opacity: .7;
        }
    }
    
    .swiper{
        padding-bottom: 30px;
    }
    
    .swiper-pagination-bullets.swiper-pagination-horizontal{
        bottom: 0px;
        
        .swiper-pagination-bullet{
            width: 9px;
            height: 9px;
            background-color: #fff;
            opacity: .5;
            
            &[aria-current=true]{
                opacity: 1;
            }
        }
    }
}




.section_title{
    @include rem(23);
    color: #fff;
    letter-spacing: .15em;
    font-weight: normal;
    margin: 0 0 25px 0;
}

.shopinfo{
    margin: 30px 0 0 0;
    padding: 0 0 30px 0;
    color: $font-color;
    font-weight: bold;
    
    background-image: url(../img/illust.webp);
    background-repeat: no-repeat;
    background-position: right 40px bottom;
    background-size: 45.56%;
    
    @include media_query(){
        padding: 235px 20px 0;
        background-position: center top;
        background-size: calc(100% - 40px);
        
        .section_title{
            display: none;
        }
    }
    
    h3{
        @include rem(21);
        letter-spacing: .32em;
        line-height: 1.5;
        margin: 0 0 25px 0;
        
        @include media_query(){
            @include rem(22);
        }
        
        span{
            display: block;
            @include rem(10);
            letter-spacing: .3em;
            
            @include media_query(){
                @include rem(11);
            }
        }
    }
    
    .address{
        @include rem(11);
        line-height: 1.7;
        letter-spacing: .14em;
        
        @include media_query(){
            @include rem(12);
        }
        
        a{
            font-weight: bold;
            color: $font-color;
            margin-left: 1em;
        }
    }
    
    .tel{
        margin-top: 6px;
        @include rem(11);
        letter-spacing: .14em;
        
        @include media_query(){
            @include rem(12);
        }
        
        a{
            color: $font-color;
        }
    }
    
    .open{
        margin-top: 20px;
        @include rem(11);
        letter-spacing: .14em;
        line-height: 1.7;
        
        @include media_query(){
            @include rem(12);
        }
    }
    
    .sns{
        display: none;
        list-style: none;
        margin-top: 35px;
        
        @include media_query(){
            display: flex;
            gap: 30px;
        }
        
        .icon_instagram{
            a{
                img{
                    width: 27px;
                    height: auto;
                }
            }
        }
        
        .icon_facebook{
            a{
                img{
                    width: 11px;
                    height: auto;
                }
            }
        }
    }
}

@use '../base/vars' as *;

.contact_inner{
    margin-top: 90px;
    
    @include media_query(){
        margin-top: 80px;
        padding: 0 40px;
    }
    
    p{
        @include rem(16);
        color: #8C6239;
        letter-spacing: .13em;
        line-height: 1.7;
        text-align: center;
        
        @include media_query(){
            @include rem(12);
        }
    }
    .cotnact_main{
        background-color: #fff;
        max-width: 810px;
        margin: 40px auto 0;
        padding: 45px 70px;
        border-radius: 20px;
        
        @include media_query(){
            padding: 20px;
            margin: 30px auto 0;
        }
        
        .cotnact_main_inner{
            .cotnact_item{
                position: relative;
                display: grid;
                grid-template-columns: 150px 1fr;
                align-items: center;
                border-bottom: solid 6px #FCCC00;
                
                &::after{
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    content: "＊";
                    @include rem(16);
                    color: #FCCC00;
                    
                    @include media_query(){
                        @include rem(12);
                    }
                }
                
                & + .cotnact_item{
                    margin-top: 20px;
                }
                
                label{
                    @include rem(16);
                    color: #8C6239;
                    letter-spacing: .15em;
                    
                    @include media_query(){
                        @include rem(12);
                    }
                }
                
                input[type=text],
                input[type=email]{
                    display: block;
                    width: 100%;
                    @include rem(16);
                    padding: 1em;
                    
                    @include media_query(){
                        @include rem(12);
                    }
                }
                
                &.textarea{
                    display: block;
                    padding: 1em 0;
                    
                    textarea{
                        margin-top: 10px;
                        display: block;
                        width: 100%;
                        height: 230px;
                    }
                }
            }
            
            input[type=submit]{
                display: block;
                width: 123px;
                height: 32px;
                margin: 60px auto 0;
                background-color: #FCCC00;
                border-radius: 12px;
                @include rem(16);
                letter-spacing: .15em;
                color: #fff;
                
                @include media_query(){
                    @include rem(12);
                    margin: 30px auto 0;
                }
            }
            
            .wpcf7-response-output{
                background-color: #ed6d3b;
                color: #fff;
                text-align: center;
                border: none;
                @include rem(13);
                padding: .8em;
            }
            
            .sent{
                .wpcf7-response-output{
                    background-color: #fccc00;
                    color: #fff;
                }
            }
            
            .wpcf7-not-valid-tip{
                @include rem(13);
                padding-bottom: .7em;
            }
        }
    }
}

@use '../base/vars' as *;

.shop{
    // margin-top: 80px;
    // margin-left: 40px;
    margin: 80px auto 0;
    // max-width: 810px;
    max-width: 745px;
    
    @include media_query(){
        margin: 80px 0 0;
    }
    
    .shop_head{
        img{
            width: 100%;
            // max-width: 544px;
            height: auto;
        }
    }
}

.page_title{
    display: none;
    
    @include media_query(){
        display: block;
        text-align: center;
        margin-bottom: 35px;
        letter-spacing: .31em;
        color: #8C6239;
    }
}

.shop_main{
    display: grid;
    grid-template-columns: 1fr 455px;
    margin-top: 60px;
    
    @include media_query(){
        grid-template-columns: 1fr;
        margin: 30px 0 0 0;
        padding: 0 40px;
    }
    
    .shopinfo{
        background: none;
        padding-left: 10px;
        margin: 0;
        
        @include media_query(){
            padding: 0;
        }
        
        .section_title{
            @include media_query(){
                display: block;
            }
        }
        
        .map{
            margin-top: 40px;
            
            @include media_query(){
                display: none;
            }
            
            a{
                @include rem(12);
                color: #804F21;
                letter-spacing: .1em;
            }
        }
    }
    
    .googlemap{
        
        @include media_query(){
            margin-top: 30px;
        }
        
        iframe{
            width: 100%;
        }
        
        .sp_link{
            display: none;
            
            @include media_query(){
                display: block;
                margin-top: 15px;
                
                a{
                    @include rem(15);
                    color: $font-color;
                }
            }
        }
    }
}

.shop_schedule{
    margin-top: 55px;
    
    @include media_query(){
        padding: 0 40px;
    }
    
    .section_title{
        padding-left: 10px;
    }
}

.xo-months{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    
    @include media_query(){
        grid-template-columns: 1fr;
    }
}

.xo-event-calendar table{
    background-color: transparent !important;
}
.xo-event-calendar table{
    color: #8C6239 !important;
    border-collapse: collapse !important;
}
.xo-event-calendar table.xo-month, .xo-event-calendar table.xo-month td, .xo-event-calendar table.xo-month th{
    border-color: #fff !important;
}
.xo-event-calendar table.xo-month>thead th{
    font-weight: normal !important;
    padding: 5px !important;
}
.dashicons-arrow-right-alt2:before,.dashicons-arrow-left-alt2:before{
    color: #804F21 !important;
}

.xo-event-calendar table.xo-month .month-dayname td div.today{
    color: #fff !important;
}
.xo-event-calendar table.xo-month>thead th.saturday{
    color: #0b87f5 !important;
}
.xo-event-calendar table.xo-month>thead th.sunday{
    color: #f5310b !important;
}
.xo-event-calendar p.holiday-title{
    color: #804F21 !important;
}

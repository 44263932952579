@use '../base/vars' as *;

html,
body{
    height: 100%;
    
    @include media_query(){
        height: auto;
    }
}

body{
    background-color: $main-color;
}

.wrap{
    display: grid;
    grid-template-columns: 200px 1fr;
    max-width: 1250px;
    height: 100%;
    margin: 0 auto;
    padding: 50px 0 0 0;
    
    @include media_query(){
        display: block;
        padding: 0;
        height: auto;
    }
}

.sidebar{
    width: 200px;
    // grid-column: 1 / 2;
    
    @include media_query(){
        width: 100%;
    }
    
    .spnone{
        @include media_query(){
            display: none !important;
        }
    }
    
    .sp_catchcopy{
        display: none;
        
        @include media_query(){
            display: block
        }
        
        padding-top: 30px;
        text-align: center;
        @include rem(13);
        font-weight: bold;
        letter-spacing: 0.35em;
        color: $font-color;
        font-weight: bold;
    }
    
    .logo{
        @include media_query(){
            text-align: center;
            margin-top: 30px;
        }
        
        img{
            width: 100%;
            height: auto;
            
            @include media_query(){
                max-width: 202px;
            }
        }
    }
}

.contents{
    height: 100%;
    // display: grid;
    // grid-template-rows: auto 1fr auto;
    padding: 55px 25px 0;
    // grid-column: 2 / 3;
    
    display: flex;
    flex-direction: column;
    
    @include media_query(){
        padding: 0;
        display: block;
    }
}

// .topcontents{
//     overflow: hidden;
    
//     @include media_query(){
//         overflow: auto;
//         height: auto;
//     }
// }

.header{
    .header_inner{
        .catchcopy{
            text-align: center;
            @include rem(18);
            font-weight: bold;
            letter-spacing: 0.35em;
            color: $font-color;
            font-weight: bold;
            
            @include media_query(){
                display: none;
            }
        }
    }
}

.globalnav{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;
    
    @include media_query(){
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        margin: 0;
        padding-top: 150px;
        background-color: rgba(#fff, .9);
        opacity: 0;
        visibility: hidden;
        transition: opacity .4s, visibility .4s;
        
        width: 100%;
        height: 100vh;
        
        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    
    ul{
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        
        @include media_query(){
            flex-direction: column;
            align-items: flex-start;
            max-width: 130px;
            margin: 0 auto;
        }
        
        li{
            @include rem(16);
            font-weight: bold;
            text-align: left;
            
            @include media_query(){
                @include rem(17);
            }
            
            &.current{
                a{
                    color: #fff;
                    cursor: text;
                    
                    &[href]:hover{
                        opacity: 1;
                    }
                    
                    @include media_query(){
                        color: #FCCC00;
                    }
                }
            }
            
            a{
                opacity: .3;
                color: $font-color;
                text-decoration: none;
                letter-spacing: 0.15em;
                
                &[href]{
                    opacity: 1;
                    
                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
    }
    
    .tools{
        
        @include media_query(){
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 45px;
        }
        
        li{
            &.icon_instagram{
                img{
                    width: 23px;
                    height: 23px;
                    vertical-align: bottom;
                    
                    @include media_query(){
                        width: 29px;
                        height: 29px;
                    }
                }
            }
            
            &.icon_facebook{
                img{
                    width: 8px;
                    height: 19px;
                    vertical-align: bottom;
                    
                    @include media_query(){
                        width: 11px;
                        height: 25px;
                    }
                }
            }
        }
    }
}

.main{
    flex: 1;
}

.footer{
    margin-top: 35px;
    padding: 20px;
    color: #fff;
    text-align: center;
    @include rem(12);
    letter-spacing: .06em;
    
    @include media_query(){
        @include rem(11);
    }
}





.menu-trigger,
.menu-trigger span {
    display: inline-block;
    transition: all .25s;
    box-sizing: border-box;
}
.menu-trigger {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 37px;
    height: 15px;
    z-index: 999;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    
    display: none;
    
    @include media_query(){
        display: block;
    }
}
.menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
}
.menu-trigger span:nth-of-type(1) {
    top: 0;
}
.menu-trigger span:nth-of-type(2) {
    bottom: 0;
}


.menu-trigger.active span:nth-of-type(1) {
    transform: translateY(6px) rotate(-25deg);
    background-color: #8C6239;
    height: 3px;
}
.menu-trigger.active span:nth-of-type(2) {
    transform: translateY(-6px) rotate(25deg);
    background-color: #8C6239;
    height: 3px;
}

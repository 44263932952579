@use '../base/vars' as *;

.news_archive{
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 165px;
    gap: 60px;
    
    @include media_query(){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
    
    .news_inner{
        
        .page_title{
            display: none;
            
            @include media_query(){
                display: block;
            }
        }
        
        .news_inner_intro{
            margin-bottom: 65px;
            
            @include media_query(){
                margin-bottom: 40px;
            }
            
            p{
                @include rem(11);
                line-height: 1.8;
                letter-spacing: .21em;
                font-weight: bold;
                color: #8C6239;
                
                @include media_query(){
                    text-align: center;
                }
            }
        }
        
        .news_item{
            & + .news_item{
                margin-top: 75px;
                
                @include media_query(){
                    margin-top: 50px;
                }
            }
            
            .news_item_head{
                display: flex;
                align-items: center;
                border-bottom: solid 1px #fff;
                padding-bottom: 15px;
                margin-bottom: 40px;
                
                @include media_query(){
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    display: block;
                }
                
                p{
                    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
                    font-weight: normal;
                    @include rem(16);
                }
                
                h1{
                    @include rem(16);
                    font-weight: bold;
                    margin-left: 65px;
                    
                    @include media_query(){
                        margin: 10px 0 0 0;
                    }
                }
            }
            
            .news_item_main{
                font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
                max-width: 450px;
                margin: 0 auto;
                @include rem(13);
                line-height: 1.8;
                
                .news_item_thumbnail{
                    margin-bottom: 25px;
                    
                    @include media_query(){
                        margin-bottom: 15px;
                    }
                    
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                
                img{
                    max-width: 100%;
                    height: auto;
                }
                
                .readmore{
                    margin-top: 1em;
                    
                    a{
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }
        }
        
    }
    
}

.cal{
    margin-top: 30px;
}

.news_sidebar{
    padding-top: 60px;
    
    .news_sidebar_inner{
        padding: 10px 0 0 50px;
        border-left: solid 1px #fff;
        height: 100%;
        
        @include media_query(){
            border-left: none;
            padding: 0;
        }
        
        h2{
            @include rem(15);
            font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
            font-weight: normal;
            
            @include media_query(){
                color: #fff;
                border-bottom: solid 1px #fff;
                padding-bottom: 10px;
            }
        }
        
        ul{
            margin-top: 20px;
            
            li{
                list-style: none;
                
                & + li{
                    margin-top: 1em;
                }
                
                
                a{
                    @include rem(12);
                    color: #000;
                    text-decoration: none;
                    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
                    font-weight: normal;
                }
            }
        }
    }
}






.sp_br{
    display: none;
    
    @include media_query(){
        display: block;
    }
}

@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use 'page/common';
@use 'page/index';
@use 'page/news';
@use 'page/shopinfo';
@use 'page/contact';


//*------------------------------------*//
//	fonts
//*------------------------------------*//
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap');
